



























































































































































































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import FormValidator from './Form.Validator';
import { UserProfile } from '@/store/modules/auth/types';
import { Utilisateur, Societe, FormeJuridique, Civilite, Contact } from '@/models';
import CeeAdresse from '@/components/CeeAdresse.vue';
import { Getter, Action } from 'vuex-class';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { ReferentielStoreMethods, getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { TypeValeurReferentielle, ValeurReferentielle } from '@/shared/models';
import { Profils, ProfilsInternes, TypeCompteSyndic as TypeCompteSyndicEnum } from '@/shared/enums';
import { TypeContact } from '@/shared/enums/TypeContact.enum';
import { mergeWith, cloneDeep } from 'lodash-es';

@Component({
    name: 'SocieteForm',
    components: { CeeAdresse },
})
export default class SocieteForm extends Mixins(FormValidator) {

    /**
     * Getter pour l'utilisateur.
     */
    @Getter(UtilisateurStoreMethods.UTILISATEUR)
    private utilisateur: Utilisateur;
    @Getter(AuthStoreMethods.USER_PROFILE)
    private getUserProfile: Promise<UserProfile>;
    @Action(UtilisateurStoreMethods.ADD_NEW_SOCIETE_TO_UTILISATEUR)
    public ajouterNouvelleSociete: (societe: Societe) => void;
    @Action(UtilisateurStoreMethods.UPDATE_SOCIETE_UTILISATEUR)
    private mettreAJourSocieteUtilisateur: (payload: { index: number, societe: Societe }) => void;

    // Profil de l'utilisateur courant.
    public profil: UserProfile;

    // Permet de faire en sorte que le template ait accès à l'enum.
    public profils = Profils;

    // Indique si le pavé est visible ou non.
    @Prop({ default: false })
    public isVisible!: boolean;

    // Seuls les admins peuvent modifier les données des sociétés
    public isReadOnly: boolean = true;

    // Les internes peuvent saisir un SIRET sans aucune validation.
    public estInterne: boolean = false;

    /**
     * Détermine si ce champ est modifiable (compte admin requis ET uniquement les nouvelles sociétés).
     * @param societe Société à évaluer.
     * @returns Booléen déterminant si le champ est modifiable.
     */
    public isChampReadOnly(societe: Societe): boolean {
        return this.isReadOnly || societe.id !== 0;
    }

    // Les valeurs référentielles.
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.Civilite))
    public civilites: Civilite[];
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.FormeJuridique))
    public formesJuridiques: FormeJuridique[];
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>

    /**
     * Hook déclenché au chargement du composant.
     */
    mounted() {
        // Chargement des valeurs référentielles.
        this.getValeursReferentielles(TypeValeurReferentielle.FormeJuridique);
        this.getValeursReferentielles(TypeValeurReferentielle.Civilite);

        // Seuls les admins peuvent modifier les données des sociétés.
        this.getUserProfile.then((profil: UserProfile) => {
            if (profil) {
                this.profil = profil;
                this.isReadOnly = !profil.isAdmin;
                this.estInterne = Object.keys(ProfilsInternes).some((key: string) => key === this.profil.profilCode);
            }
        });

        if (this.utilisateur.societes.length === 0) {
            this.emptySocietes();
        }
    }

    /**
     * Indique si le profil de l'utilisateur actuel est du type passé en paramètre.
     * @param typeProfil Profil à tester.
     **/
    public estProfilDuType(typeProfil: Profils): boolean {
        const typedProfilString: keyof typeof Profils = this.utilisateur
            ? (this.utilisateur.profilUtilisateur as keyof typeof Profils)
            : 'None';
        return Profils[typedProfilString] === typeProfil;
    }

    // Vrai si le type de compte est SDC, faux sinon.
    public get estTypeCompteSDC(): boolean {
        return this.estProfilDuType(Profils.SyndicSDC) && this.utilisateur.typeCompteSyndicId === TypeCompteSyndicEnum.SDC;
    }

    /**
     * Détermine si le SIRET est requis pour cette société.
     * @param societe Société à analyser.
     * @returns Vrai sauf si on a coché la case "Pas de numéro SIRET" pour un compte SDC.
     */
    public estSiretRequis(societe: Societe) {
        return !(this.estTypeCompteSDC && societe.estSansSiret);
    }

    // Règle de validation propre au SIRET.
    public getSiretRequisRule(societe: Societe) {
        return [
            (v: string | any) => this.checkPresenceSiret(societe) || 'Le numéro SIRET est obligatoire. Confirmez que vous n\'en possédez pas, ou saisissez le.',
        ]
    };

    /**
     * Vérifie que le est SIRET est présent sauf dérogation.
     */
    private checkPresenceSiret(societe: Societe): boolean {

        const siretSaisi = (societe.siret.length > 0);
        if (this.estTypeCompteSDC) {
            // On autorise un SIRET vide seulement si on est un Syndic qui a déclaré ne pas avoir de SIRET avec la checkbox prévue à cet effet.
            return (societe.estSansSiret || siretSaisi);
        }

        return siretSaisi;
    }

    /**
     * Watcher pour ajouter une société par défaut quand le bloc passe visible ou pour tout vider quand il devient non visible.
     * */
    @Watch('isVisible')
    public onVisibilityChanged() {
        if (this.isVisible && this.utilisateur.societes.length === 0) {
            this.addSociete();
        } else if (!this.isVisible && this.utilisateur.societes.length > 0) {
            this.emptySocietes();
        }
    }

    /**
     * Crée un signataire vide pour la société si ce n'est pas l'utilisateur courant.
     */
    public onSignataireSocieteChanged(societeParam: Societe, index: number, $event: boolean) {
        const estUtilisateurSignataire = $event;
        const mergeSociete: Societe = mergeWith(cloneDeep(societeParam), { estUtilisateurSignataire });

        // Supprime le contact existant si l'utilisateur courant est maintenant le signataire.
        mergeSociete.contacts = mergeSociete.contacts.filter(c => c.typeContactId !== TypeContact.Signataire);

        // Vérifie la nouvelle valeur.
        if (mergeSociete.estUtilisateurSignataire) {
            // Le signataire devient l'utilisateur courant.
            mergeSociete.signataire = new Contact(TypeContact.Signataire, this.utilisateur);
        }
        else {
            // Crée un nouveau contact (signataire) sinon.
            mergeSociete.signataire = new Contact(TypeContact.Signataire);
        }
        mergeSociete.contacts.push(mergeSociete.signataire);
        this.mettreAJourSocieteUtilisateur({ index, societe: mergeSociete });
    }

    /**
     * Crée un interlocuteur vide pour la société si ce n'est pas l'utilisateur courant.
     */
    public onInterlocuteurSocieteChanged(societeParam: Societe, index: number, $event: boolean) {
        const estUtilisateurInterlocuteur = $event;
        const mergeSociete: Societe = mergeWith(cloneDeep(societeParam), { estUtilisateurInterlocuteur });

        // Supprime le contact existant si l'utilisateur courant est maintenant l'interlocuteur.
        mergeSociete.contacts = mergeSociete.contacts.filter(c => c.typeContactId !== TypeContact.Interlocuteur);

        // Vérifie la nouvelle valeur.
        if (mergeSociete.estUtilisateurInterlocuteur) {
            // L'interlocuteur devient l'utilisateur courant.
            mergeSociete.interlocuteur = new Contact(TypeContact.Interlocuteur, this.utilisateur);
        }
        else {
            // Crée un nouveau contact (interlocuteur) sinon.
            mergeSociete.interlocuteur = new Contact(TypeContact.Interlocuteur);
        }
        mergeSociete.contacts.push(mergeSociete.interlocuteur);
        this.mettreAJourSocieteUtilisateur({ index, societe: mergeSociete });
    }

    // Initialisation des sociétés.
    public emptySocietes() {
        this.utilisateur.societes = new Array<Societe>();
        this.utilisateur.societes.push(new Societe());
    }

    /**
     * Ajout d'une société.
     * */
    public addSociete() {
        const newSociete = new Societe();
        newSociete.utilisateurId = this.utilisateur.id;
        // this.utilisateur.societes.push(newSociete);
        this.ajouterNouvelleSociete(newSociete);
    }

    /**
     * Suppression d'une société.
     * */
    public removeSociete() {
        this.utilisateur.societes.pop();
    }
}
