





































































































import { Component, Mixins } from 'vue-property-decorator';
import { ValidationProvider } from "vee-validate";
import { Getter, Action } from 'vuex-class';
import { ReferentielStoreMethods, getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';

import FormValidator from './Form.Validator';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
import { ValeurReferentielle, TypeValeurReferentielle } from '@/shared/models';
import { TypeCompteSyndic } from '@/models/TypeCompteSyndic.model';
import { Utilisateur, Civilite } from '@/models';
import { Profils } from '@/shared/enums';

@Component({
    name: 'IdentityForm',
    components: {
        ValidationProvider,
    },
})
export default class IdentityForm extends Mixins(FormValidator) {

    /**
     * L'utilisateur du store.
     */
    @Getter(UtilisateurStoreMethods.UTILISATEUR)
    private utilisateur: Utilisateur;

    /**
     * Récupération des données référentielles.
     */
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.TypeCompteSyndic))
    public typesCompteSyndic: TypeCompteSyndic[];
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.Civilite))
    public civilites: Civilite[];
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>;

    /**
     * Détermine si l'utilisateur est un Syndic/SDC.
     */
    public get isTypeCompteSyndicSDC(): boolean {
        return (this.utilisateur.profilUtilisateur === Profils.SyndicSDC);
    }

    /**
     * Récupération des valeurs référentielles.
     */
    public mounted(): void {
        this.getValeursReferentielles(TypeValeurReferentielle.Civilite);
        this.getValeursReferentielles(TypeValeurReferentielle.TypeCompteSyndic);
    }
}

