import { CompteValidationMixin } from '@/shared/mixins';
import { Component } from 'vue-property-decorator';

@Component({})
export default class FormValidator extends CompteValidationMixin {

        private prescripteurInterneRules = [
            (v: string | any) => !!v || 'Le prescripteur interne est obligatoire',
        ];

        private acceptationCGURules = [
            (v: string | any) => this.required(v) || 'Veuillez prendre connaissance et accepter les conditions générales d\'utilisation',
        ];

        private confirmationInformationsRules = [
            (v: string | any) => this.required(v) || 'La confirmation des informations saisies est obligatoire',
        ];

        private dateDesactivation = [
            (v: string | any) => this.required(v) || 'La date de désactivation est obligatoire',
        ];
}
